import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RouteURI } from '@router';
import { IAppState, isAuthenticated } from '@store';
import { map } from 'rxjs/internal/operators/map';

export const authGuard: CanActivateFn | CanActivateChildFn = (_route, state, store = inject(Store<IAppState>), router = inject(Router)) => {
  return store.select(isAuthenticated).pipe(
    map(authenticated => {
      if (!authenticated) {
        const params: Params = {};
        const uris = state.url.split('/');
        if (uris[uris.length - 1] !== RouteURI.APPLICATION_FORM) {
          params['returnUrl'] = state.url;
        }
        router.navigate(['login'], { queryParams: params });
        return false;
      }
      return true;
    })
  );
};
